<template>
  <div class="entrega-retirada">
    <div class="p-2">
      <p class="lead">Opções de entrega ou retirada</p>

      <div class="form-group">
        <label for="selectEntregaRetirada">Entrega ou retirada?</label>
        <select
          class="form-control form-control-sm"
          v-model="opcao.metodo"
          id="selectEntregaRetirada"
        >
          <option></option>
          <option>Entrega</option>
          <option>Retirada na loja</option>
        </select>
      </div>

      <div v-if="opcao.metodo == 'Entrega'" class="form-group">
        <label for="selectDiaHorario">Dia e horário</label>
        <select
          class="form-control form-control-sm"
          v-model="opcao.agendamento"
          id="selectDiaHorario"
        >
          <option></option>
          <option>Quarta-feira de 10h às 14h</option>
          <option>Quarta-feira de 16h às 18h</option>
          <option>Sábado de 10h às 14h</option>
        </select>
      </div>

      <div v-if="opcao.metodo == 'Retirada na loja'" class="form-group">
        <label for="selectDiaHorario">Dia e horário</label>
        <select
          class="form-control form-control-sm"
          v-model="opcao.agendamento"
          id="selectDiaHorario"
        >
          <option></option>
          <option>Quarta-feira de 09h às 18h</option>
          <option>Sábado de 09h às 15h</option>
        </select>
      </div>

      <div class="form-group">
        <label for="inputObservacoes">Observações</label>
        <textarea
          class="form-control form-control-sm"
          id="inputObservacoes"
          rows="2"
          placeholder="Observações sobre o pedido"
          v-model="observacoes"
        ></textarea>
      </div>
      <button
        class="btn btn-primary btn-block btn-sm"
        @click="$emit('confirmar')"
      >
        OK
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      opcao: {},
    };
  },
  methods: {
    ...mapMutations({
      setOpcaoEntregaRetirada: "setOpcaoEntregaRetirada",
      setObservacoesPedido: "setObservacoesPedido",
    }),
  },
  computed: {
    ...mapGetters({
      opcaoEntregaRetirada: "getOpcaoEntregaRetirada",
      observacoesPedido: "getObservacoesPedido",
    }),
    observacoes: {
      get() {
        return this.observacoesPedido;
      },
      set(value) {
        this.setObservacoesPedido(value);
      },
    },
  },
  watch: {
    opcao: {
      handler() {
        this.setOpcaoEntregaRetirada(this.opcao);
      },
      deep: true,
    },
  },
  created() {
    this.opcao = this.opcaoEntregaRetirada;
  },
};
</script>
