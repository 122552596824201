<template>
  <div class="py-2">
    <p class="lead">Formas de pagamento</p>
    <ul class="list-group list-group-flush">
      <li
        class="list-group-item"
        v-for="op in opcoesPagamento"
        :key="op.codigo"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            :id="op.codigo"
            name="opcaoPagamento"
            :value="op"
            v-model="opcao"
          />
          <label class="form-check-label" :for="op.codigo">{{ op.nome }}</label>
        </div>
      </li>
    </ul>
    <button
      class="btn btn-primary btn-block btn-sm"
      @click.prevent="$emit('confirmar')"
    >
      OK
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations({ setOpcaoPagamento: "setOpcaoPagamento" }),
  },
  computed: {
    ...mapGetters({
      opcoesPagamento: "getOpcoesPagamento",
      opcaoPagamento: "getOpcaoPagamento",
    }),
    opcao: {
      get() {
        return this.opcaoPagamento;
      },
      set(value) {
        this.setOpcaoPagamento(value);
      },
    },
  },
};
</script>
