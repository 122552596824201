<template>
  <div class="checkout">
    <div class="container-fluid step-container">
      <strong class="d-block mb-3 text-center">Dados</strong>
      <ul class="list-group mb-2">
        <checklist-item 
          icon="user" 
          label="Meus dados" 
          :is-valid="clienteOK" 
          @click="navigate('/dados-do-cliente')" 
        />
        <checklist-item 
          icon="money-check-alt" 
          label="Como vou pagar" 
          :is-valid="opcaoPagamentoOK" 
          @click="toggleForm('pagamento')" 
        />
        <checklist-item 
          icon="truck" 
          label="Entrega / Retirada" 
          :is-valid="opcaoEntregaRetiradaOK" 
          @click="toggleForm('entrega')" 
        />
      </ul>

      <transition mode="out-in" name="fade">
        <div v-if="isLoading" class="text-center">
          <div class="spinner-border text-warning my-4" role="status">
            <span class="sr-only">Carregando...</span>
          </div>
        </div>
      </transition>

      <transition mode="out-in" name="fade">
        <div v-if="msgSucesso" class="finalizacao">
          <div class="alert alert-success" role="alert">
            {{ msgSucesso }}
          </div>
          <router-link
            :to="{ name: 'Finalizacao' }"
            class="btn btn-outline-success btn-block mb-2"
            title="Pagar"
          >
            Pagar
          </router-link>
        </div>
        <div v-else class="d-flex">
          <button
            @click="concluirPedido"
            :class="{ disabled: isLoading }"
            class="btn btn-block btn-success mb-2"
          >
            <div class="d-flex justify-content-between align-items-center font-weight-bold">
              <span>Concluir pedido</span>
              <font-awesome-icon
                icon="shopping-cart"
                :style="{ color: '#fff' }"
              />
            </div>
          </button>
        </div>
      </transition>

      <router-link
        :to="{ name: 'Pagamento' }"
        class="btn btn-outline-info btn-sm btn-block mb-3 font-weight-bold shadow-sm"
        title="Informações de pagamento"
      >
        Informações de pagamento
      </router-link>

      <div v-if="alertas.length">
        <transition-group mode="out-in" name="fade">
          <div
            class="alert alert-warning"
            role="alert"
            v-for="(alerta, i) in alertas"
            :key="`alert-${i}`"
            @click="removerAlerta(i)"
          >
            {{ alerta }}
          </div>
        </transition-group>
      </div>
    </div>

    <transition mode="out-in" name="slide-fade">
      <component :is="currentForm" @confirmar="toggleForm(null)" class="px-2 checkout__card-form" />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import PedidoService from "../../service/PedidoService";
import OpcoesPagamento from "./components/OpcoesPagamento.vue";
import OpcoesEntrega from "./components/OpcoesEntrega.vue";
import ChecklistItem from "./components/ChecklistItem.vue";

import {
  validarPedido,
  validarCliente,
  validarOpcaoEntregaRetirada,
  validarOpcaoPagamento,
} from "../../util/functions";

export default {
  name: "Checkout",
  components: {
    OpcoesPagamento,
    OpcoesEntrega,
    ChecklistItem,
  },
  data() {
    return {
      pedidoService: new PedidoService(),
      isLoading: false,
      formType: null, // Alterna entre 'pagamento' e 'entrega'
      msgSucesso: "",
      responseData: {},
      alertas: [],
    };
  },
  methods: {
    ...mapMutations({
      resetState: "resetState",
      setPedidoFinalizado: "setPedidoFinalizado",
    }),
    concluirPedido() {
      this.alertas = validarPedido(this.pedido);
      if (this.alertas.length === 0) {
        this.isLoading = true;
        setTimeout(() => {
          this.pedidoService
            .enviarPedido(this.pedido)
            .then((response) => {
              this.msgSucesso = response.data.msg;
              this.responseData = response.data;
              this.resetState();
              this.setPedidoFinalizado(this.responseData.pedido);
            })
            .catch((error) => (this.alertas = error.response.data))
            .finally(() => (this.isLoading = false));
        }, 1500);
      }
    },
    removerAlerta(index) {
      this.alertas.splice(index, 1);
    },
    toggleForm(type) {
      this.formType = this.formType === type ? null : type;
    },
    navigate(path) {
      this.$router.push(path);
    },
  },
  computed: {
    ...mapGetters({
      cliente: "getCliente",
      pedido: "getPedido",
      opcaoEntregaRetirada: "getOpcaoEntregaRetirada",
      opcaoPagamento: "getOpcaoPagamento",
      valorTotal: "valorTotal",
    }),
    clienteOK() {
      return validarCliente(this.cliente).length === 0;
    },
    opcaoPagamentoOK() {
      return validarOpcaoPagamento(this.opcaoPagamento).length === 0;
    },
    opcaoEntregaRetiradaOK() {
      return validarOpcaoEntregaRetirada(this.opcaoEntregaRetirada).length === 0;
    },
    currentForm() {
      return this.formType === 'pagamento' ? 'OpcoesPagamento' : (this.formType === 'entrega' ? 'OpcoesEntrega' : null);
    },
  },
};
</script>
