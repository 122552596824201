import api from "./api";

export default class PedidoService {

    constructor() {
        this.engineUrl = "/admin/engine/painel/lista-de-compras.php";
    }

    enviarPedido(pedido) {
        return api().post(
            this.engineUrl,
            pedido,
            { params: { action: "registar-pedido" } }
        );
    }

}
