<template>
    <li class="list-group-item">
      <a
        href="#"
        @click.prevent="handleClick"
        class="d-flex justify-content-between align-items-center"
      >
        <font-awesome-icon :icon="icon" :style="{ color: '#f36f24' }" />
        <span>{{ label }}</span>
        <font-awesome-icon
          v-if="isValid"
          icon="check-circle"
          :style="{ color: '#4cb848' }"
        />
        <font-awesome-icon
          v-else
          icon="times-circle"
          :style="{ color: 'red' }"
        />
      </a>
    </li>
  </template>
  
  <script>
  export default {
    name: "ChecklistItem",
    props: {
      icon: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      isValid: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleClick() {
        this.$emit('click');
      },
    },
  };
  </script>
  