<template>
  <div class="home">
    <!-- Passo a Passo -->
    <div class="container-fluid step-container">
      <strong class="d-block mb-3 text-center">Tutorial</strong>
      <div class="d-flex flex-wrap justify-content-around align-items-start text-center">
        <div class="step-wrapper">
          <button class="step-button mb-2">
            <font-awesome-icon class="mr-2" />
            Passo 1
          </button>
          <p class="step-description">Escolha os produtos nas categorias.</p>
        </div>
        <div class="step-wrapper">
          <button class="step-button mb-2">
            <font-awesome-icon class="mr-2" />
            Passo 2
          </button>
          <p class="step-description">Preencha seus dados, selecione a forma de pagamento e de entrega/retirada.</p>
        </div>
        <div class="step-wrapper">
          <button class="step-button mb-2">
            <font-awesome-icon class="mr-2" />
            Passo 3
          </button>
          <p class="step-description">Finalize seu pedido e realize o pagamento.</p>
        </div>
      </div>
    </div>
    <!-- Fim do Passo a Passo -->
    
    <div class="container-fluid step-container">
      <strong class="d-block mb-3 text-center">Categorias de Produtos</strong>
      <div class="d-flex flex-wrap justify-content-center">
        <div
          class="category-card-wrapper mb-3"
          v-for="categoria in categorias"
          :key="categoria.id"
        >
          <div class="card text-center category-card shadow">
            <div class="card-body d-flex flex-column align-items-center">
              <font-awesome-icon
                icon="seedling"
                size="2x"
                :style="{ color: '#4cb848' }"
                class="mb-2"
              />
              <router-link
                :to="{ name: 'Produtos', params: { categoria: categoria.id } }"
                class="stretched-link"
              >
                <h5 class="card-title">{{ categoria.nome }}</h5>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <checkout />
    <div class="container-fluid step-container text-center">
      <div class="py-2 contact-info">
        <strong class="d-block mb-3 text-center">Contatos</strong>
        <div class="d-flex flex-wrap justify-content-center">
          <a
            href="tel:+5502733218330"
            class="btn contact-button mb-2 mr-2 shadow-sm"
          >
            <font-awesome-icon icon="phone-alt" class="mr-2" />
            (27) 3321-8330
          </a>
          <a
            href="tel:+55027999036504"
            class="btn contact-button mb-2 shadow-sm"
          >
            <font-awesome-icon icon="mobile-alt" class="mr-2" />
            (27) 99903-6504
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Checkout from "@/components/checkout/Checkout.vue";

export default {
  name: "Home",
  components: { Checkout },
  computed: {
    ...mapGetters({
      categorias: "getCategorias",
    }),
  },
};
</script>